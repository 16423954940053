import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Navigation from "../components/Navigation";
import ContactForm from '../components/ContactForm';
import Layout from "../components/Layout";
import Footer from '../components/Footer';

import theme from "../styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";

library.add(fab, faEnvelope);

const Contact = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Contact | Diana Ignatescu</title>
      <link rel="canonical" href="https://dianaignatescu.com/contact" />
    </Helmet>
    <Navigation />
    <ContactDetails>
      <ContactHeader>
        <h2>Contact Me</h2>
        <p>Feel free to get in touch with any questions you may have and I'll get back to you as soon as I can.</p>
      </ContactHeader>

      <SocialIconsContact>
        <div className="contact">
          <OutboundLink href="mailto:diana.ignatescu8@gmail.com" className="social-icon" >
            <FontAwesomeIcon icon={"envelope"} />
          </OutboundLink>
          <OutboundLink href="mailto:diana.ignatescu8@gmail.com">
            diana.ignatescu8@gmail.com
          </OutboundLink>
        </div>

        <div className="contact">
          <OutboundLink href="https://www.linkedin.com/in/dianaignatescu/" className="social-icon" >
            <FontAwesomeIcon icon={["fab", "linkedin"]} />
          </OutboundLink>
          <OutboundLink href="https://www.linkedin.com/in/dianaignatescu/">
            linkedin.com/in/dianaignatescu/
          </OutboundLink>
        </div>

        <div className="contact">
          <OutboundLink href="https://github.com/DianaIgnatescu" className="social-icon" >
            <FontAwesomeIcon icon={["fab", "github"]} />
          </OutboundLink>
          <OutboundLink href="https://github.com/DianaIgnatescu">
            github.com/DianaIgnatescu
          </OutboundLink>
        </div>
      </SocialIconsContact>
    </ContactDetails>
    <ContactForm />
    <Footer />
  </Layout>
);

export default Contact;

const ContactHeader = styled.div`
  width: 800px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
  }

  h2 {
    color: ${theme.colors.lightYellow};
    line-height: normal;
  }
  
  p {
    padding-top: 2rem;
    color: ${theme.colors.lightYellow};
    line-height: normal;
    font-size: 2rem;
  }
`;

const ContactDetails = styled.div`
  width: 800px;
  margin: 0 auto;
  
  @media (max-width: 800px) {
    width: 100%;
    padding: 0 2.5rem;
  }
`;

const SocialIconsContact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  .contact {
    display: flex;
    padding-top: 0.5rem;
    
    @media(max-width: 400px) {
      flex-direction: column;
      .social-icon {
        text-align: center;
        display: none;
      }
    }
    
    a {
      color: ${theme.colors.lightYellow};
      font-size: 2rem;
      padding-right: 1.5rem;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        transition: 0.3s ease;
        text-decoration: underline;
      }
    }
  }
`;
