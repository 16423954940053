import React from 'react';
import styled from 'styled-components';
import theme from "../styles";

const ContactForm = () => (
  <ContactFormWrapper>
    <p>Alternatively, you can fill in the form below.</p>
    <StyledForm name="contact" method="post" data-netlify-honeypot="bot-field" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <label>
        Name
        <input type="text" name="name" id="name" />
      </label>

      <label>
        Email
        <input type="email" name="email" id="email" />
      </label>

      <label>
        Subject
        <input type="text" name="subject" id="subject" />
      </label>

      <label>
        Message
        <textarea name="message" id="message" rows="5" />
      </label>
      <div className="form-buttons">
        <input type="reset" value="Clear" />
        <button type="submit">Send</button>
      </div>
    </StyledForm>
  </ContactFormWrapper>
);

export default ContactForm;

const ContactFormWrapper = styled.div`
width: 800px;
  margin: 0 auto;
  padding: 2rem 0 6rem 0;
  @media(max-width: 800px) {
  padding: 2.5rem;
  width: 100%;
  }
  p {
    color: ${theme.colors.lightYellow};
    line-height: normal;
    font-size: 2rem;
    padding-top: 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 600px;
  @media(max-width: 600px) {
    width: 100%;
  }

  label {
    color: ${theme.colors.lightYellow};
    font-size: 2rem;
    padding-top: 1.7rem;
  
    input {
      width: 100%;
      background: rgba(253,249,223, 0.15);
      border: none;
      height: 40px;
      padding: 5px 10px;
      color: ${theme.colors.lightYellow};
    }
  }
  
  textarea {
    width: 100%;
    background: rgba(253,249,223, 0.15);
    border: none;
    padding: 5px 10px;
    color: ${theme.colors.lightYellow};
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem 0;
    input {
      width: 150px;
      background: none;
      border: 2px solid ${theme.colors.lightYellow};
      color: ${theme.colors.lightYellow};
      font-size: 2rem;
      font-weight: 300;
      &:hover {
        background-size: 4px 50px;
        cursor: pointer;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
    
    button {
      width: 150px;
      margin-left: 2rem;
      background: ${theme.colors.lightYellow};
      border: 2px solid ${theme.colors.lightYellow};
      color: ${theme.colors.backgroundPink};
      font-size: 2rem;
      font-weight: 300;
      &:hover {
        background-size: 4px 50px;
        color: ${theme.colors.backgroundOrange};
        cursor: pointer;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }
`;
